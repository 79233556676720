import React from "react";
import AllBags from "./AllBags"; 
import AllKits from "./AllKits";

function AllProducts() {
  return (
    <div>
      <AllBags />
      <AllKits />
    </div>
  );
}

export default AllProducts;
